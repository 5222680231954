import { InputText } from "./InputText.tsx";
import { FieldPath, FieldValues } from "react-hook-form";
import { InputHTMLAttributes } from "react";
import styles from "./input.module.scss";

type InputDateProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  helpText?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputDate = <T extends FieldValues>({
  ...rest
}: InputDateProps<T>) => {
  return (
    <div className={styles.overskrivIOS}>
      <InputText {...rest} type={"date"} max={"2006-12-29"} />
    </div>
  );
};
