import { StepProps } from "../SignUp.tsx";
import { Fieldset } from "../../../components/Fieldset.tsx";
import { InputText } from "../../../components/input/InputText.tsx";
import { Registration } from "../../../types/registration.types.ts";
import { useFormContext } from "react-hook-form";
import { RadioButtonGroup } from "../../../components/input/RadioButtonGroup.tsx";
import { options } from "../signup.utils.ts";

export const JewishConnection = ({ classname, title }: StepProps) => {
  const { watch } = useFormContext<Registration>();
  const hasRelation = watch("connection.hasRelationToJewishCommunity");
  return (
    <Fieldset className={classname} title={title}>
      <RadioButtonGroup<Registration>
        name={"connection.hasRelationToJewishCommunity"}
        label={
          "Do you have a relation to a jewish community, organization, etc?"
        }
        options={options}
      />
      {hasRelation === "YES" ? (
        <InputText<Registration>
          name={"connection.relationToJewishCommunity"}
          label={"Describe your relation"}
        />
      ) : (
        <></>
      )}
      {hasRelation === "NO" ? (
        <>
          <p>
            We need a reference person since you have answered that you have no
            relation to a jewish institution
          </p>
          <InputText<Registration>
            name={"connection.referencePerson.name.firstname"}
            label={"Firstname of reference"}
          />
          <InputText<Registration>
            name={"connection.referencePerson.name.lastname"}
            label={"Lastname of reference"}
          />
          <InputText<Registration>
            name={"connection.referencePerson.contactInformation.phonenumber"}
            label={"Phonenumber of reference"}
          />
          <InputText<Registration>
            name={"connection.referencePerson.contactInformation.countrycode"}
            label={"Countrycode"}
          />
        </>
      ) : (
        <></>
      )}
    </Fieldset>
  );
};
