import styles from "./components.module.scss";
import { CSSProperties } from "react";
import cn from "classnames";
export const SectionHeader = ({
  title,
  style,
  classname,
}: {
  title: string;
  style?: CSSProperties;
  classname?: string;
}) => {
  return (
    <span className={cn(styles.header, classname)} style={style}>
      {title}
    </span>
  );
};
