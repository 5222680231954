import imgOne from "../../assets/forsamling.jpg";
import imgTwo from "../../assets/panel.jpg";
import imgThree from "../../assets/sjuf_malmo_1947.jpeg";
import styles from "./program.module.scss";

export type ProgramType = {
  img: string;
  day: string;
  about: string[];
  price: string;
  imgClassName: string;
  className?: string;
};

export const programList: Array<ProgramType> = [
  {
    img: imgOne,
    day: "29.12.24",
    about: [
      "We cordially invite you to one of Oslo's most exclusive banquet halls, where we will indulge in an elegant three-course dinner and an abundance of wine. The evening will feature live music, hora dancing, speeches, and various other surprises. The SJUF banquet is a formal event, requiring your finest attire. Prepare yourself for an unforgettable evening as we welcome SJUF back to Oslo for the first time in 20 years!",
    ],
    price: "Price: 1450 NOK",
    imgClassName: styles.cardOne,
  },
  {
    img: imgTwo,
    day: "30.12.24",
    about: [
      "The day will commence with lectures delivered by some of the world's foremost experts on captivating topics related to Judaism and Jewish life. Later in the evening, we have reserved an entire nightclub exclusively for SJUF participants, where we will dance the night away. Anticipate several surprises that you have never before experienced at SJUF!",
    ],
    price: "Price: 150 NOK",
    imgClassName: styles.cardTwo,
  },
  {
    img: imgThree,
    day: "31.12.24",
    about: [
      "Prepare yourself for the most extraordinary New Year's Eve celebration. Don your finest suit and perhaps apply 4-5 sprays of cologne, and we will handle the rest. We hope you enjoy champagne, as this will be an evening for the history books.",
    ],
    price: "Price: 1150 NOK",
    imgClassName: styles.cardThree,
  },
];
