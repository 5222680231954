import { createBrowserRouter, RouterProvider } from "react-router-dom";
import Layout from "./components/layout/Layout.tsx";
import { QueryClient, QueryClientProvider } from "@tanstack/react-query";
import { Sjuf } from "./sjuf/Sjuf.tsx";
import { AuthContextProvider, Authenticated } from "./auth/Auth.tsx";

const queryClient = new QueryClient();

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <>
        <Layout />
      </>
    ),
    children: [
      {
        path: "/",
        element: <Sjuf />,
      },
    ],
  },
]);

function App() {
  return (
    <div className={"App"}>
      <QueryClientProvider client={queryClient}>
        <AuthContextProvider>
          <Authenticated>
            <RouterProvider router={router} />
          </Authenticated>
        </AuthContextProvider>
      </QueryClientProvider>
    </div>
  );
}

export default App;
