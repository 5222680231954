import {
  AnswerType,
  Name,
  Registration,
  ContactInformation as ContactInformationType,
} from "../../types/registration.types.ts";
import { v4 as uuidv4 } from "uuid";
import { InputRadioOption } from "../../components/input/RadioButtonGroup.tsx";
import React from "react";
import { PersonInformation } from "./steps/PersonInformation.tsx";
import { ContactInformation } from "./steps/ContactInformation.tsx";
import { FoodInformation } from "./steps/FoodInformation.tsx";
import { SjufPracticalInformation } from "./steps/SjufPracticalInformation.tsx";
import { Summary } from "./steps/Summary.tsx";
import { StepProps } from "./SignUp.tsx";
import { PlusOne } from "./steps/PlusOne.tsx";
import { JewishConnection } from "./steps/JewishConnection.tsx";

export const options: Array<InputRadioOption<AnswerType>> = [
  { label: "Yes", value: "YES" },
  { label: "No", value: "NO" },
];

const defaultName: Name = {
  firstname: "",
  middlename: "",
  lastname: "",
};
const defaultContactinformation: ContactInformationType = {
  email: "",
  countrycode: "",
  phonenumber: "",
};
export const defaultRegistration: Registration = {
  id: uuidv4(),
  person: {
    name: defaultName,
    contactInformation: defaultContactinformation,
    nationality: "",
    birthdate: "",
  },
  connection: {
    hasRelationToJewishCommunity: undefined,
    referencePerson: {
      name: defaultName,
      contactInformation: defaultContactinformation,
    },
    relationToJewishCommunity: "",
  },
  food: {
    allergies: {
      type: undefined,
      allergies: "",
    },
    kosherWine: undefined,
  },
  partner: {
    hasPlusOne: undefined,
    name: defaultName,
    isJewish: undefined,
  },
  wantsSjufHotel: undefined,
  participatingDays: [
    {
      participating: false,
      date: "2024-12-29",
    },
    {
      participating: false,
      date: "2024-12-30",
    },
    {
      participating: false,
      date: "2024-12-31",
    },
  ],
  other: "",
};

type RegisterComponent = {
  component: React.FC<StepProps>;
  title: string;
};
export const registerSteps: Array<RegisterComponent> = [
  {
    component: PersonInformation,
    title: "About you",
  },
  {
    component: ContactInformation,
    title: "Your contact information",
  },
  {
    component: SjufPracticalInformation,
    title: "Participation information",
  },
  {
    component: JewishConnection,
    title: "Connection to jewish community, jewish organization, etc",
  },
  {
    component: FoodInformation,
    title: "Food information",
  },
  {
    component: PlusOne,
    title: "Plus one information",
  },
  {
    component: Summary,
    title: "Summary",
  },
];

export const inputOrNoAnswer = (
  input?: string,
  customUndefinedMessage: string = "No answer",
): string => {
  return input || customUndefinedMessage;
};
