import React from "react";
import cn from "classnames";
import styles from "./components.module.scss";

type ButtonProps = {
  text?: string;
  style?: string;
} & React.DetailedHTMLProps<
  React.ButtonHTMLAttributes<HTMLButtonElement>,
  HTMLButtonElement
>;

export const Button = ({
  text,
  style,
  type = "button",
  ...rest
}: ButtonProps) => {
  return (
    <button {...rest} className={cn(styles.button, style)} type={type}>
      {text}
    </button>
  );
};
