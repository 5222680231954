import { FieldPath, FieldValues, useFormContext } from "react-hook-form";
import { SyntheticEvent } from "react";
import Checkbox from "./Checkbox.tsx";
import styles from "./input.module.scss";
import { Registration } from "../../types/registration.types.ts";

export type InputCheckboxGroupProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  options: InputCheckboxOption[];
  helpText?: string;
  onInput?: React.EventHandler<SyntheticEvent>;
  className?: string;
  onBlur?: React.EventHandler<SyntheticEvent>;
  property?: string;
};

export type InputCheckboxOption<T = string> = {
  label: string;
  value: T;
  helpText?: string;
  disabled?: boolean;
};

export const CheckboxGroup = <T extends FieldValues>({
  name,
  label,
  options,
  onBlur,
  onInput,
  helpText,
  property,
}: InputCheckboxGroupProps<T>) => {
  const { watch } = useFormContext<Registration>();
  console.log(watch("participatingDays"));
  return (
    <fieldset onBlur={onBlur} className={styles.checkboxContainer}>
      <legend className={styles.label}>{label}</legend>
      {helpText && <span id={`${name}_helpText`}>{helpText}</span>}
      {options.map((option, index) => {
        const fieldname: FieldPath<T> = property
          ? (`${name}.[${index}].${property}` as FieldPath<T>)
          : (`${name}.[${index}]` as FieldPath<T>);
        return (
          <div key={`${name}_${option.value}`}>
            <Checkbox
              name={fieldname}
              onInput={onInput}
              onBlur={(e) => {
                e.stopPropagation();
              }}
              label={option.label}
              className={styles.space}
              value={option.value}
            />
            <span id={`${name}_helpText`}>{option.helpText}</span>
          </div>
        );
      })}
    </fieldset>
  );
};
