import { ReactNode } from "react";
import { Outlet } from "react-router-dom";
import styles from "./layout.module.scss";
type LayoutProps = {
  children?: ReactNode;
};

const Layout = ({ children }: LayoutProps) => {
  return (
    <div className={styles.layout}>
      <main>
        <Outlet />
        {children}
      </main>
    </div>
  );
};

export default Layout;
