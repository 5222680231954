import cn from "classnames";
import styles from "./signup.module.scss";
import Container from "../../components/layout/Container.tsx";
import { FieldErrors, FormProvider, useForm } from "react-hook-form";
import { AnswerType, Registration } from "../../types/registration.types.ts";
import { defaultRegistration, registerSteps } from "./signup.utils.ts";
import { BaseSyntheticEvent, useEffect, useState } from "react";
import { Fieldset } from "../../components/Fieldset.tsx";
import { Button } from "../../components/Button.tsx";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import { ObjectSchema } from "yup";
import signupApi from "./signup.api.ts";
import { ErrorReceipt, Receipt } from "./Receipt.tsx";
import { useSignup } from "./SignupContext.tsx";
import { Icon } from "../../components/Icon.tsx";

type SignUpProps = {
  className?: string;
};

const FormButtons = ({
  setCurrentStep,
  currentStep,
  classname,
}: {
  setCurrentStep: (value: number) => void;
  currentStep: number;
  classname: string;
}) => {
  return (
    <div className={classname}>
      <Button
        onClick={() => {
          setCurrentStep(currentStep - 1);
        }}
        disabled={currentStep === 0}
        text={"Back"}
      />
      <Button
        onClick={() => {
          setCurrentStep(currentStep + 1);
        }}
        hidden={currentStep === registerSteps.length - 1}
        text={"Next"}
      />
      <Button
        type={"submit"}
        hidden={currentStep !== registerSteps.length - 1}
        text={"Sign up"}
      />
    </div>
  );
};
const StepIndicator = ({
  currentStep,
  setCurrentStep,
}: {
  currentStep: number;
  setCurrentStep: (index: number) => void;
}) => {
  return (
    <div className={styles.stepIndicator}>
      {registerSteps.map((_titleValue, titleIndex) => {
        return (
          <div className={styles.step} key={titleIndex}>
            <Button
              style={cn(
                styles.button,
                currentStep === titleIndex ? styles.inFocus : "",
              )}
              text={(titleIndex + 1).toString()}
              onClick={() => setCurrentStep(titleIndex)}
            />
          </div>
        );
      })}
    </div>
  );
};

export type StepProps = {
  classname?: string;
  title: string;
};

const signupSchema: ObjectSchema<Registration> = Yup.object().shape({
  id: Yup.string().required("Id is required"),
  person: Yup.object()
    .shape({
      name: Yup.object()
        .shape({
          firstname: Yup.string().required("Your firstame is required"),
          middlename: Yup.string().notRequired(),
          lastname: Yup.string().required("Your lastname is required"),
        })
        .required("Name is required"),
      contactInformation: Yup.object()
        .shape({
          email: Yup.string().email().required("Your email is required"),
          countrycode: Yup.string().required("Your countrycode is required"),
          phonenumber: Yup.string().required("Your phonenumber is required"),
        })
        .required("Contact information is required"),
      nationality: Yup.string().required("Your nationality is required"),
      birthdate: Yup.string().required("Your birthdate is required"),
    })
    .required("Person is required"),
  connection: Yup.object().shape({
    hasRelationToJewishCommunity: Yup.mixed<AnswerType>().required(
      "Has a relation to a jewish community, etc is required",
    ),
    relationToJewishCommunity: Yup.string().when(
      "hasRelationToJewishCommunity",
      (hasRelationToJewishCommunity: any[], schema) => {
        if (hasRelationToJewishCommunity[0] === "YES")
          return schema.required("A description of your relation is required");
        return schema;
      },
    ),
    referencePerson: Yup.object().when(
      "hasRelationToJewishCommunity",
      (hasRelationToJewishCommunity: any[], schema) => {
        if (hasRelationToJewishCommunity[0] === "NO")
          return schema
            .shape({
              name: Yup.object().shape({
                firstname: Yup.string().required(
                  "Your reference persons firstname is required",
                ),
                middlename: Yup.string().notRequired(),
                lastname: Yup.string().required(
                  "Your reference persons lastname is required",
                ),
              }),
              contactInformation: Yup.object().shape({
                countrycode: Yup.string().required(
                  "Your reference persons countrycode is required",
                ),
                phonenumber: Yup.string().required(
                  "Your reference persons phonenumber is required",
                ),
              }),
            })
            .required("A reference person is required");
        else return schema;
      },
    ),
  }),
  partner: Yup.object().shape({
    hasPlusOne: Yup.mixed<AnswerType>().required(
      "Do you have a plus one is required",
    ),
    name: Yup.object().when("hasPlusOne", (hasPlusOne, schema) => {
      if (hasPlusOne[0] === "YES")
        return schema.shape({
          firstname: Yup.string().required(
            "Your plus one's firstame is required",
          ),
          middlename: Yup.string().notRequired(),
          lastname: Yup.string().required(
            "Your plus one's lastname is required",
          ),
        });
      else return schema;
    }),
    isJewish: Yup.mixed<AnswerType>().when(
      "hasPlusOne",
      (hasPlusOne, schema) => {
        if (hasPlusOne[0] === "YES")
          return schema.required("Is your plus one jewish is required");
        else return schema;
      },
    ),
  }),
  food: Yup.object().shape({
    kosherWine: Yup.mixed<AnswerType>().required(
      "Do you want kosher wine is required",
    ),
    allergies: Yup.object().shape({
      type: Yup.mixed<AnswerType>().required(
        "Do you have any allergies is required",
      ),
      allergies: Yup.string().when("type", (type, schema) => {
        if (type[0] === "YES")
          return schema.required("Description of allergies is required");
        else return schema;
      }),
    }),
  }),
  wantsSjufHotel: Yup.mixed<AnswerType>().required(
    "Are you planning to stay at the participating hotel is required",
  ),
  participatingDays: Yup.array()
    .of(
      Yup.object().shape({
        date: Yup.string().required("Participating dates is required"),
        participating: Yup.boolean().required(
          "Participating dates is required",
        ),
      }),
    )
    .required("Participating dates is required"),
  other: Yup.string(),
});

export const SignUp = ({ className }: SignUpProps) => {
  const form = useForm<Registration>({
    defaultValues: defaultRegistration,
    resolver: yupResolver(signupSchema),
  });
  const { setShowReceipt, showReceipt, wantsReceipt, mail, setMail } =
    useSignup();

  const onSubmit = (data: Registration) =>
    signupApi
      .createRegistration(data, wantsReceipt ? mail : undefined)
      .then(() => {
        setShowReceipt({ receipt: true });
        form.reset({}, { keepDefaultValues: true });
        setCurrentStep(0);
      })
      .catch(() => setShowReceipt({ error: true }));
  const onError = (
    errors: FieldErrors<Registration>,
    e: BaseSyntheticEvent | undefined,
  ) => console.log(errors, e);
  const [currentStep, setCurrentStep] = useState<number>(0);
  const { component: Step, title } = registerSteps[currentStep];

  const showErrorPage = showReceipt["error"];
  const showReceiptPage = showReceipt["receipt"];

  const mailForm = form.watch("person.contactInformation.email");

  useEffect(() => {
    if (mailForm) setMail(mailForm);
  }, [mailForm]);
  return (
    <Container className={cn(className)}>
      {!showReceiptPage && !form.formState.isSubmitting && (
        <FormProvider {...form}>
          <form onSubmit={form.handleSubmit(onSubmit, onError)} id={"form"}>
            <Fieldset title={"Sign up"} className={styles.formContainer}>
              <div>
                <StepIndicator
                  currentStep={currentStep}
                  setCurrentStep={setCurrentStep}
                />
              </div>
              <Step title={title} />
              <FormButtons
                setCurrentStep={setCurrentStep}
                currentStep={currentStep}
                classname={styles.entireRow}
              />
            </Fieldset>
          </form>
        </FormProvider>
      )}
      {form.formState.isSubmitting && (
        <>
          <span className={styles.loading}>Submitting registration</span>
          <Icon type={"sync"} modifierClass={styles.icon} />
        </>
      )}
      {showErrorPage && (
        <ErrorReceipt
          data={form.getValues()}
          resetRegistration={() => {
            form.reset({}, { keepDefaultValues: true });
            setCurrentStep(0);
          }}
        />
      )}
      {showReceiptPage && <Receipt />}
    </Container>
  );
};
