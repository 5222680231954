import { InputText } from "../../../components/input/InputText.tsx";
import { Registration } from "../../../types/registration.types.ts";
import { InputDate } from "../../../components/input/InputDate.tsx";
import { Fieldset } from "../../../components/Fieldset.tsx";
import { StepProps } from "../SignUp.tsx";

export const PersonInformation = ({ classname, title }: StepProps) => {
  return (
    <Fieldset className={classname} title={title}>
      <InputText<Registration>
        name={"person.name.firstname"}
        label={"First name"}
      />
      <InputText<Registration>
        name={"person.name.middlename"}
        label={"Middle name"}
      />
      <InputText<Registration>
        name={"person.name.lastname"}
        label={"Last name"}
      />
      <InputDate<Registration>
        name={"person.birthdate"}
        label={"Date of birth"}
      />
      <InputText<Registration>
        name={"person.nationality"}
        label={"Nationality"}
      />
    </Fieldset>
  );
};
