import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import React, { HTMLProps, SyntheticEvent } from "react";
import styles from "./input.module.scss";
import { InputRadioOption } from "./RadioButtonGroup.tsx";
import cn from "classnames";

type InputRadioButtonProps<T extends FieldValues> = {
  name: FieldPath<T>;
  option: InputRadioOption;
  onInput?: React.EventHandler<SyntheticEvent>;
} & HTMLProps<HTMLInputElement>;
export const RadioButton = <T extends FieldValues>({
  name,
  option,
  onInput,
  ...rest
}: InputRadioButtonProps<T>) => {
  const { control } = useFormContext();
  const { field } = useController({ name, control });

  return (
    <label className={cn(styles.radioRow)}>
      <input
        {...rest}
        {...field}
        type={"radio"}
        checked={field.value + "" === option.value}
        value={option.value}
        id={`${name}_${option.value}`}
        onInput={onInput}
      />
      {option.label}
    </label>
  );
};
