import styles from "../sjuf.module.scss";
import { Button } from "../../components/Button.tsx";
import cn from "classnames";
import { useInView } from "react-intersection-observer";
import hjorne from "../../assets/hjorne.png";
import logo from "../../assets/hvitSJUF.png";
import bubbles from "../../assets/blubbspark.png";
import cali from "../../assets/topCall.png";

export const Title = () => {
  const { ref, inView } = useInView({
    threshold: 0.1,
  });

  return (
    <section
      style={{
        position: "relative",
        height: "90vh",
        display: "flex",
        flexDirection: "column",
        marginTop: "2rem",
        gap: "2rem",
      }}
    >
      <img
        src={hjorne}
        alt={"bobler"}
        className={cn(styles.fellesImg, styles.leftTopImg)}
      />
      <img
        src={hjorne}
        alt={"bobler"}
        className={cn(styles.fellesImg, styles.leftBottomImg)}
      />
      <img
        src={hjorne}
        alt={"bobler"}
        className={cn(styles.fellesImg, styles.rightBottomImg)}
      />
      <img
        src={hjorne}
        alt={"bobler"}
        className={cn(styles.fellesImg, styles.rightTopImg)}
      />

      <div className={styles.frame}>
        <img style={{ width: "8rem" }} src={logo} alt={"Logo"} />
        <img src={cali} alt={"monster"} className={styles.topImage} />
      </div>
      <div className={styles.frame2}>
        <img src={bubbles} alt={"bobler"} className={styles.bubbles} />
        <div
          className={cn(
            styles.pageTitle,
            styles.animatedTitle,
            inView && styles.visible,
          )}
          ref={ref}
        >
          <span>Join SJUF Oslo</span>
          <span>2024</span>
          <Button
            text={"Book Tickets"}
            style={cn("mt-8", styles.bookButtom)}
            onClick={() => {
              const form = document.getElementById("form");
              if (!form) return;
              return form.scrollIntoView({ behavior: "smooth" });
            }}
          />
        </div>
      </div>
    </section>
  );
};
