import { StepProps } from "../SignUp.tsx";
import { Fieldset } from "../../../components/Fieldset.tsx";
import { Registration } from "../../../types/registration.types.ts";
import { RadioButtonGroup } from "../../../components/input/RadioButtonGroup.tsx";
import { options } from "../signup.utils.ts";
import { useFormContext } from "react-hook-form";
import { InputText } from "../../../components/input/InputText.tsx";

export const PlusOne = ({ classname, title }: StepProps) => {
  const { watch } = useFormContext<Registration>();
  const hasPlusOne = watch("partner.hasPlusOne");
  return (
    <Fieldset className={classname} title={title}>
      <RadioButtonGroup<Registration>
        name={"partner.hasPlusOne"}
        label={"Do you have a plus one that is attending sjuf?"}
        options={options}
      />
      {hasPlusOne === "YES" ? (
        <>
          <RadioButtonGroup<Registration>
            name={"partner.isJewish"}
            label={"Is your plus one jewish?"}
            options={options}
          />
          <InputText<Registration>
            name={"partner.name.firstname"}
            label={"Firstname of plus one"}
          />
          <InputText<Registration>
            name={"partner.name.lastname"}
            label={"Lastname of plus one"}
          />
        </>
      ) : (
        <></>
      )}
    </Fieldset>
  );
};
