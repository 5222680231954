import Container from "../components/layout/Container.tsx";
import { Title } from "./title/Title.tsx";
import { About } from "./about/About.tsx";
import { Program } from "./program/Program.tsx";
import { Board } from "./board/Board.tsx";
import { Socials } from "./socials/Socials.tsx";
import { SignUp } from "./signup/SignUp.tsx";
import "./sjuf.css";
import { Support } from "./support/Support.tsx";
import { SignupContextProvider } from "./signup/SignupContext.tsx";

export const Sjuf = () => {
  return (
    <Container>
      <Title />
      <About />
      <Program />
      <Board />
      <Socials />
      <Support />
      <SignupContextProvider>
        <SignUp />
      </SignupContextProvider>
    </Container>
  );
};
