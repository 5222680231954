import React, { createContext, ReactNode, useContext, useState } from "react";

interface SignupContextReturn {
  mail?: string;
  setMail: (string: string) => void;
  wantsReceipt: boolean;
  setWantsReceipt: (value: boolean) => void;
  showReceipt: { [key: string]: boolean };
  setShowReceipt: (value: { [key: string]: boolean }) => void;
}

const signupContextInit: SignupContextReturn = {
  mail: undefined,
  setMail: () => {
    throw new Error("Not Implemented");
  },
  wantsReceipt: false,
  setWantsReceipt: () => {
    throw new Error("Not Implemented");
  },
  showReceipt: {
    error: false,
    reciept: false,
  },
  setShowReceipt: () => {
    throw new Error("Not Implemented");
  },
};

const SignupContext = createContext<SignupContextReturn>(signupContextInit);

export const SignupContextProvider: React.FC<{
  children: ReactNode;
}> = ({ children }) => {
  const [wantsReceipt, setWantsReceipt] = useState<boolean>(false);
  const [mail, setMail] = useState<string | undefined>(undefined);
  const [showReceipt, setShowReceipt] = useState<{ [key: string]: boolean }>({
    error: false,
    reciept: false,
  });
  const contextValue: SignupContextReturn = {
    wantsReceipt,
    setWantsReceipt,
    mail,
    setMail,
    showReceipt,
    setShowReceipt,
  };
  console.log("created context with values: ", contextValue);
  return (
    <SignupContext.Provider value={contextValue}>
      {children}
    </SignupContext.Provider>
  );
};

export const useSignup = (): SignupContextReturn => {
  const signupContext = useContext(SignupContext);
  if (!signupContext) {
    throw new Error("Signup must be used within a provider");
  }
  return {
    ...signupContext,
  };
};
