import styles from "../sjuf.module.scss";
import { Fieldset } from "../../components/Fieldset.tsx";
import kultur from "../../assets/Kulturdirektoratet-hvit.png";
import lnu from "../../assets/LNU2.png";
import drobak from "../../assets/logodrobak.png";
import dmt from "../../assets/HvitDmt.png";
import stock from "../../assets/Hvitsjufstockholm.png";
import miff from "../../assets/miff.png";
import juDanmark from "../../assets/JuDanmark.png";
import { SectionHeader } from "../../components/SectionHeader.tsx";

export const Thanks = () => {
  return (
    <Fieldset className={styles.thanks} legendStyle={styles.header}>
      <SectionHeader title={"Thank you"} style={{ fontSize: "4rem" }} />
      <ul className={styles.thanksList}>
        {thanksList.map((org) => (
          <li key={org}>{org}</li>
        ))}
        <div style={{ marginTop: "1rem" }}>
          {thankslistImages.map((org) => {
            if (Array.isArray(org)) {
              return (
                <li
                  key={org.join(",")}
                  style={{
                    display: "flex",
                    gap: "1rem",
                    justifyContent: "center",
                  }}
                >
                  {org.map((value) => (
                    <img
                      src={value}
                      alt={"Image"}
                      className={styles.logoFlexSize}
                    />
                  ))}
                </li>
              );
            } else {
              return (
                <li key={org}>
                  <img src={org} alt={"Image"} />
                </li>
              );
            }
          })}
        </div>
      </ul>
    </Fieldset>
  );
};

const thanksList: Array<string> = [
  "Drøbak Invest AS",
  "Heckscherstiftelsen",
  "Det Mosaiske Trossamfund",
  "SJUF Stockholm",
  "LNU for Mangfold og inkluderingsstøtta",
  "Kulturdirektoratet",
  "Internasjonale Kristne Ambassade Jerusalem",
  "Clas Groschinskys minnefond",
  "Europeiska Investeringsrådgivarna",
  "Med Israel for Fred",
  "Israelittiske Ynglingaforeningen",
  "Beth och Julius Goldmans Donation",
  "JU Danmark",
  "L. Lazer Medical Consulting AB",
  "Hersons minnefond",
  "SJUF-kongressen Oslo 1994",
  "Cambio - simply the BEST trousers",
  "ABO Eiendommer",
  "ITTA",
];
const thankslistImages: Array<string | Array<string>> = [
  lnu,
  kultur,
  dmt,
  stock,
  [miff, juDanmark],
];

type DonateType = {
  name: string;
  value: string;
};
const donateList: Array<DonateType> = [
  { name: "Account number", value: "1520.04.83089" },
  { name: "IBAN", value: "NO98 1520 0483 089" },
  { name: "Swift", value: "DNBANOKKXXX" },
  { name: "Address", value: "Dronning Eufemias Gate 30, 0191, Oslo" },
];

export const Donate = () => {
  return (
    <Fieldset
      title={"Support us?"}
      className={styles.alignCenter}
      legendStyle={styles.legend}
    >
      <ul className={styles.thanksList}>
        {donateList.map((donate) => (
          <li key={donate.name}>{`${donate.name}: ${donate.value}`}</li>
        ))}
      </ul>
    </Fieldset>
  );
};

export const Support = () => {
  return (
    <section className={styles.support}>
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <SectionHeader
          title={"Main sponsor:"}
          style={{
            textAlign: "center",
            fontSize: "4rem",
            marginBottom: "2rem",
          }}
        />
        <img
          src={drobak}
          style={{ maxWidth: "800px", marginBottom: "2rem" }}
          alt={"mainSponsor"}
        />
      </div>
      <Thanks />
      <Donate />
    </section>
  );
};
