import styles from "../sjuf.module.scss";
import { boardMembers1, boardMembers2 } from "./board.ts";
import { BoardMemberCard } from "./BoardMemberCard.tsx";
import { SectionHeader } from "../../components/SectionHeader.tsx";

export const Board = () => {
  return (
    <section className={styles.board}>
      <SectionHeader title={"Meet the board"} />
      <div className={styles.boardMembersContainer}>
        {boardMembers1.map((member) => (
          <BoardMemberCard key={member.name} boardMember={member} />
        ))}
      </div>
      <div className={styles.boardMembersContainer}>
        {boardMembers2.map((member) => (
          <BoardMemberCard key={member.name} boardMember={member} />
        ))}
      </div>
    </section>
  );
};
