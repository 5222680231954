import styles from "./program.module.scss";
import { programList } from "./program.ts";
import { ProgramCard } from "./ProgramCard.tsx";
import { SectionHeader } from "../../components/SectionHeader.tsx";
import hjorne from "../../assets/hjorne.png";
import cn from "classnames";

export const Program = () => {
  return (
    <section className={styles.program}>
      <SectionHeader
        style={{ textAlign: "center" }}
        title={"What do we offer?"}
      />
      <div className={styles.programContainer}>
        {programList.map((day) => (
          <ProgramCard key={day.day} day={day} />
        ))}
      </div>
      <div className={styles.packageContainer}>
        <img
          src={hjorne}
          alt={"bobler"}
          className={cn(styles.fellesImg, styles.leftTopImg)}
        />
        <img
          src={hjorne}
          alt={"bobler"}
          className={cn(styles.fellesImg, styles.rightBottomImg)}
        />
        <SectionHeader
          title={"2052 KR FOR THE ENTIRE PACKAGE!"}
          classname={styles.package}
        />
      </div>
    </section>
  );
};
