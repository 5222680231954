import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import { InputHTMLAttributes } from "react";

export type InputCheckboxProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
} & InputHTMLAttributes<HTMLInputElement>;

const InputCheckbox = <T extends FieldValues>({
  name,
  label,
  onBlur: customOnBlur,
  ...rest
}: InputCheckboxProps<T>) => {
  const { control } = useFormContext();
  const { field } = useController({ name, control });

  return (
    <div>
      <input
        type={"checkbox"}
        id={name}
        {...rest}
        {...field}
        value={undefined}
        checked={field.value || false}
        onChange={(e) => {
          field.onChange(e.target.checked);
        }}
        onBlur={(e) => {
          field.onBlur();
          customOnBlur && customOnBlur(e);
        }}
      />
      <label>{label}</label>
    </div>
  );
};

export default InputCheckbox;
