import { RadioButtonGroup } from "../../../components/input/RadioButtonGroup.tsx";
import { Registration } from "../../../types/registration.types.ts";
import { InputTextArea } from "../../../components/input/InputTextArea.tsx";
import { options } from "../signup.utils.ts";
import { Fieldset } from "../../../components/Fieldset.tsx";
import { StepProps } from "../SignUp.tsx";
import { useFormContext } from "react-hook-form";

export const FoodInformation = ({ classname, title }: StepProps) => {
  const { watch } = useFormContext<Registration>();
  const allergies = watch("food.allergies.type");
  return (
    <Fieldset className={classname} title={title}>
      <RadioButtonGroup<Registration>
        name={"food.allergies.type"}
        label={"Do you have any allergies or dietary restrictions?"}
        helpText={"(Food served by SJUF is kosher)"}
        options={options}
      />
      {allergies === "YES" ? (
        <InputTextArea<Registration>
          name={"food.allergies.allergies"}
          label={"Describe your allergies"}
        />
      ) : (
        <></>
      )}
      <RadioButtonGroup<Registration>
        name={"food.kosherWine"}
        label={"Do you want kosher wine?"}
        helpText={"(Wine is not kosher)"}
        options={options}
      />
    </Fieldset>
  );
};
