import image from "../../assets/hvaErSjuf.jpg";
import star from "../../assets/davidstar.svg";
import styles from "../sjuf.module.scss";
import { SectionHeader } from "../../components/SectionHeader.tsx";

export const About = () => {
  return (
    <section className={styles.about}>
      <img src={image} alt={"What is SJUF"} />
      <div className={styles.information}>
        <SectionHeader title={"What is SJUF?"} />
        <p>
          The annual New Year Congress of SJUF (Scandinavian Jewish Youth
          Association) has been held for over 100 years. The goal of SJUF is
          social interaction and professional enrichment, enabling individuals
          to feel confident in their Jewish identity.
        </p>
        <p>Age: 18-30 years</p>
        <img src={star} alt={"Star of david"} />
      </div>
    </section>
  );
};
