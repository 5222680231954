import { InputText } from "../../../components/input/InputText.tsx";
import { Registration } from "../../../types/registration.types.ts";
import { Fieldset } from "../../../components/Fieldset.tsx";
import { StepProps } from "../SignUp.tsx";

export const ContactInformation = ({ classname, title }: StepProps) => {
  const onlyNumbers = (event: React.FormEvent<HTMLInputElement>) => {
    event.currentTarget.value = event.currentTarget.value.replace(/\D/g, "");
  };
  return (
    <Fieldset className={classname} title={title}>
      <InputText<Registration>
        name={"person.contactInformation.email"}
        label={"Email"}
      />
      <div>
        <InputText<Registration>
          name={"person.contactInformation.countrycode"}
          label={"Countrycode"}
          maxLength={6}
          onInput={onlyNumbers}
        />
        <InputText<Registration>
          name={"person.contactInformation.phonenumber"}
          label={"Phonenumber"}
          maxLength={15}
          onInput={onlyNumbers}
        />
      </div>
    </Fieldset>
  );
};
