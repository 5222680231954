import {
  InputRadioOption,
  RadioButtonGroup,
} from "../../../components/input/RadioButtonGroup.tsx";
import { Registration } from "../../../types/registration.types.ts";
import { options } from "../signup.utils.ts";
import { CheckboxGroup } from "../../../components/input/CheckboxGroup.tsx";
import { InputTextArea } from "../../../components/input/InputTextArea.tsx";
import { Fieldset } from "../../../components/Fieldset.tsx";
import { StepProps } from "../SignUp.tsx";
import { useFormContext } from "react-hook-form";
import { useState } from "react";

const participatingDaysOptions: Array<InputRadioOption> = [
  { label: "29.12.2024, 1450kr", value: "2024-12-29" },
  { label: "30.12.2024, 150kr", value: "2024-12-30" },
  { label: "31.12.2024, 1150kr", value: "2024-12-31" },
];
const datePrice: Array<{ price: number; date: string }> = [
  { price: 1450, date: "2024-12-29" },
  { price: 150, date: "2024-12-30" },
  { price: 1150, date: "2024-12-31" },
];
export const SjufPracticalInformation = ({ classname, title }: StepProps) => {
  const { watch } = useFormContext<Registration>();

  const days = watch("participatingDays");
  const [totalPrice, setTotalPrice] = useState<number>(0);
  const calculateTotalPrice = () => {
    const price = days
      .filter((day) => day.participating)
      .map(
        (value) =>
          datePrice.find((datePrice) => datePrice.date === value.date)!!.price,
      )
      .reduce((prevVal, acc) => prevVal + acc, 0);
    if (price == 2750) setTotalPrice(2052);
    else setTotalPrice(price);
  };
  return (
    <Fieldset className={classname} title={title}>
      <RadioButtonGroup<Registration>
        name={"wantsSjufHotel"}
        label={"Are you planning to stay at the participating hotel?"}
        options={options}
      />
      <CheckboxGroup<Registration>
        label={"What dates are you participating?"}
        name={"participatingDays"}
        options={participatingDaysOptions}
        property={"participating"}
        onInput={calculateTotalPrice}
      />
      {totalPrice ? (
        <span style={{ fontSize: "1.5rem", marginBottom: "1rem" }}>
          Total price: {totalPrice} kr
        </span>
      ) : (
        <></>
      )}
      <InputTextArea<Registration>
        name={"other"}
        label={"Is there something else you would like to add?"}
      />
    </Fieldset>
  );
};
