import axios from "axios";

const axiosClient = axios.create({ baseURL: "/api" });
interface AuthApi {
  isLoggedIn: () => Promise<boolean>;
}

export const authApi: AuthApi = {
  async isLoggedIn(): Promise<boolean> {
    try {
      const axiosResponse = await axiosClient.get("/isLoggedIn");
      return axiosResponse.status === 200;
    } catch (e: unknown) {
      return false;
    }
  },
};
