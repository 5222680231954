import { ReactElement, ReactNode } from "react";
import styles from "./components.module.scss";

export const Dl = ({
  children,
}: {
  children: ReactElement<typeof Entry> | ReactElement<typeof Entry>[];
}) => {
  return <dl className={styles.dl}>{children}</dl>;
};
const Entry = ({
  title,
  children,
  vertical,
}: {
  title: string;
  children: ReactNode;
  vertical?: boolean;
}) => {
  return (
    <div className={vertical ? styles.shouldVertical : undefined}>
      <dt>{title}</dt>
      <dd>{children}</dd>
    </div>
  );
};

Dl.Entry = Entry;
export default Dl;
