import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import { InputHTMLAttributes, useCallback, useEffect, useRef } from "react";
import styles from "./input.module.scss";

type InputTextAreaProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  helpText?: string;
} & InputHTMLAttributes<HTMLTextAreaElement>;

export const InputTextArea = <T extends FieldValues>({
  name,
  label,
  helpText,
  ...rest
}: InputTextAreaProps<T>) => {
  const { control } = useFormContext();
  const { field } = useController({ name, control });

  const textAreaRef = useRef<HTMLTextAreaElement>();

  const resizeTextArea = useCallback(() => {
    const textAreaEl = textAreaRef.current;
    if (textAreaEl) {
      textAreaEl.style.minHeight = "auto";
      textAreaEl.style.minHeight = textAreaEl.scrollHeight + "px";
      textAreaEl.style.resize = "none";
      textAreaEl.style.overflowY = "hidden";
    }
  }, []);

  useEffect(() => {
    resizeTextArea();
  }, [field.value, resizeTextArea]);

  return (
    <div className={styles.inputTextContainer}>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      {helpText && (
        <span id={`${name}_helpText`} className={styles.helpText}>
          {helpText}
        </span>
      )}
      <textarea
        {...rest}
        {...field}
        name={name}
        id={name}
        className={styles.inputTextArea}
        ref={(e) => {
          field.ref(e);
          textAreaRef.current = e ?? undefined;
        }}
      />
    </div>
  );
};
