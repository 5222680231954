import { SectionHeader } from "../../components/SectionHeader.tsx";
import { Button } from "../../components/Button.tsx";
import { useSignup } from "./SignupContext.tsx";
import { Registration } from "../../types/registration.types.ts";
import { useEffect, useState } from "react";
import signupApi from "./signup.api.ts";
import styles from "./signup.module.scss";

export const Receipt = () => {
  const { wantsReceipt, setShowReceipt, setWantsReceipt, mail, setMail } =
    useSignup();
  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        gap: "1rem",
        alignItems: "center",
      }}
    >
      <SectionHeader title={"Thank you for signing up"} />
      {wantsReceipt && <span>A receipt has been sent to {mail}</span>}
      <span>
        We will shortly send you a confirmation mail including payment details.
      </span>
      <Button
        text={"Sign up another person"}
        onClick={() => {
          setWantsReceipt(false);
          setShowReceipt({ receipt: false, error: false });
          setMail("");
        }}
      />
    </div>
  );
};

export const ErrorReceipt = ({
  data,
  resetRegistration,
}: {
  data: Registration;
  resetRegistration: () => void;
}) => {
  const [body, setBody] = useState<string | null>(null);
  useEffect(() => {
    signupApi.getDataHtml(data).then((r) => setBody(r));
  }, []);
  const { setWantsReceipt, setShowReceipt, setMail } = useSignup();
  return (
    <dialog className={styles.modal}>
      <div
        id={"errorReceipt"}
        style={{
          display: "flex",
          flexDirection: "column",
          gap: "1rem",
          alignItems: "center",
        }}
      >
        <SectionHeader title={"Something went wrong while registering"} />
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "1rem",
            justifyContent: "center",
          }}
        >
          <span>Please try again later{body && " or:"}</span>
          {body && (
            <>
              <span>Download the signup data and send it to us by email</span>
              <FileDownload content={body} />
            </>
          )}
          <div
            style={{ display: "flex", gap: "1rem", justifyContent: "center" }}
          >
            {body && (
              <Button
                text={"Finish"}
                onClick={() => {
                  setWantsReceipt(false);
                  setShowReceipt({ error: false, receipt: false });
                  setMail("");
                  resetRegistration();
                }}
              />
            )}
            <Button
              text={"Close"}
              onClick={() => {
                setShowReceipt({ error: false });
              }}
            />
          </div>
        </div>
      </div>
    </dialog>
  );
};

export const FileDownload = ({ content }: { content: string }) => {
  const generateFile = (content: string) => {
    // Step 1: Create the content you want to save

    // Step 2: Create a Blob from the content
    const blob = new Blob([content], { type: "text/html" });

    // Step 3: Create a URL for the Blob
    const url = URL.createObjectURL(blob);

    // Step 4: Create a link and trigger the download
    const link = document.createElement("a");
    link.href = url;
    link.download = "signupData.html"; // Filename for the downloaded file
    document.body.appendChild(link);
    link.click();

    // Cleanup: Remove the link after downloading
    document.body.removeChild(link);
    URL.revokeObjectURL(url);
  };

  return (
    <div>
      <Button
        onClick={() => generateFile(content)}
        text={"Download signup data"}
      />
    </div>
  );
};
