import { ProgramType } from "./program.ts";
import styles from "./program.module.scss";
import cn from "classnames";
import { useInView } from "react-intersection-observer";

type ProgramCardProps = {
  day: ProgramType;
};

export const ProgramCard = ({ day }: ProgramCardProps) => {
  const { ref, inView } = useInView();
  return (
    <div className={cn(styles.programCard, day.className)}>
      <div className={styles.cardText}>
        <span style={{ fontSize: "2rem" }}>{day.day}</span>
        {day.about.map((about) => (
          <p key={about}>{about}</p>
        ))}
      </div>
      <p className={styles.price}>{day.price}</p>
      <img
        ref={ref}
        src={day.img}
        alt={"Programimage"}
        className={cn(styles.img, inView && day.imgClassName)}
      />
    </div>
  );
};
