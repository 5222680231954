import React, { ReactNode, useContext, useMemo } from "react";
import { useQuery, useQueryClient } from "@tanstack/react-query";
import { authApi } from "./auth.api.ts";

export type AuthContextType = {
  isLoggedIn: boolean;
  invalidateIsLoggedIn: () => void;
};

export const AuthContext = React.createContext<AuthContextType>({
  isLoggedIn: false,
  invalidateIsLoggedIn: () => {
    throw new Error("Not implemented");
  },
});

type AuthContextProviderProps = {
  children: ReactNode;
};
export const AuthContextProvider: React.FC<AuthContextProviderProps> = ({
  children,
}) => {
  const isLoggedInQuery = useQuery({
    queryKey: ["isLoggedIn"],
    queryFn: authApi.isLoggedIn,
    throwOnError: true,
  });
  const queryClient = useQueryClient();
  const contextValue = useMemo(
    () => ({
      isLoggedIn: !!isLoggedInQuery.data,
      invalidateIsLoggedIn: () =>
        queryClient.invalidateQueries({
          queryKey: ["isLoggedIn"],
        }),
    }),
    [isLoggedInQuery.data],
  );

  if (isLoggedInQuery.isLoading) {
    return <div>Loading</div>;
  }
  return (
    <AuthContext.Provider value={contextValue}>{children}</AuthContext.Provider>
  );
};

export const Authenticated = ({ children }: AuthContextProviderProps) => {
  const contextValue = useContext(AuthContext);
  if (!contextValue.isLoggedIn) {
    contextValue.invalidateIsLoggedIn();
    return null;
  } else {
    return children;
  }
};
