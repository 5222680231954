import styles from "./components.module.scss";
import { HTMLProps, ReactElement } from "react";
import cn from "classnames";

type FielsetProps = {
  title?: string;
  children: ReactElement | ReactElement[];
  legendStyle?: string;
} & HTMLProps<HTMLFieldSetElement>;

export const Fieldset = ({
  title,
  children,
  className,
  legendStyle,
  ...rest
}: FielsetProps) => {
  return (
    <fieldset {...rest} className={cn(styles.fieldset, className)}>
      {title && (
        <legend className={cn(styles.legend, legendStyle)}>{title}</legend>
      )}
      {children}
    </fieldset>
  );
};
