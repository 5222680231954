import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import React, { SyntheticEvent } from "react";
import { RadioButton } from "./RadioButton.tsx";
import styles from "./input.module.scss";

export type InputRadioProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  options: InputRadioOption[];
  helpText?: string;
  onInput?: React.EventHandler<SyntheticEvent>;
  className?: string;
  onBlur?: React.EventHandler<SyntheticEvent>;
};

export type InputRadioOption<T = string> = {
  label: string;
  value: T;
  helpText?: string;
  disabled?: boolean;
};

export const RadioButtonGroup = <T extends FieldValues>({
  name,
  label,
  options,
  onBlur,
  onInput,
  helpText,
}: InputRadioProps<T>) => {
  const { control } = useFormContext();
  const { fieldState } = useController({ name, control });
  return (
    <fieldset onBlur={onBlur} className={styles.radioContainer}>
      <legend className={styles.label}>{label}</legend>
      {helpText && <span id={`${name}_helpText`}>{helpText}</span>}
      {options.map((option) => (
        <div key={`${name}_${option.value}`}>
          <RadioButton
            name={name}
            option={option}
            onInput={onInput}
            onBlur={(e) => {
              e.stopPropagation();
            }}
          />
          <span id={`${name}_helpText`} className={styles.helpText}>
            {option.helpText}
          </span>
        </div>
      ))}
      <p>{fieldState.error?.message}</p>
    </fieldset>
  );
};
