import itta from "../../assets/kontakt3.jpg";
import styles from "./socials.module.scss";
import "./socials-icon.scss";
import { SectionHeader } from "../../components/SectionHeader.tsx";
import { ReactNode } from "react";
import hjorne from "../../assets/hjorne.png";
import cn from "classnames";

export const Socials = () => {
  return (
    <section className={styles.socials}>
      <SectionHeader title={"Contact Us"} />
      <div className={styles.socials}>
        <img src={itta} alt={"Socials image"} className={styles.img} />
        <div className={styles.socialsContainer}>
          {socialsList.map((social) => (
            <SocialsElement key={social.name} social={social} />
          ))}
        </div>
      </div>
    </section>
  );
};

type SocialsType = {
  site: string;
  name: string;
  href: string;
  classname: string;
  icon?: string;
};
type SocialsProps = {
  social: SocialsType;
};
export const socialsList = [
  {
    site: "Instagram",
    name: "sjufoslo",
    href: "https://www.instagram.com/sjufoslo/",
    classname: "fa fa-instagram",
  },
  {
    site: "Email Address",
    name: "sjufoslo@gmail.com",
    href: "mailto:sjufoslo@gmail.com",
    classname: "material-symbols-outlined mail",
    icon: "mail",
  },
];
export const SocialsElement = ({ social }: SocialsProps) => {
  return (
    <Frame>
      <div className={styles.socialsElement}>
        <span>{social.site}</span>
        <span className={social.classname}>
          {social.icon || ""}
          <a className={styles.link} href={social.href} />
        </span>
        <span>{social.name}</span>
      </div>
    </Frame>
  );
};

const Frame = ({ children }: { children: ReactNode }) => {
  return (
    <div className={styles.frameContainer}>
      <img
        src={hjorne}
        alt={"bobler"}
        className={cn(styles.fellesImg, styles.leftTopImg)}
      />
      <img
        src={hjorne}
        alt={"bobler"}
        className={cn(styles.fellesImg, styles.rightBottomImg)}
      />
      {children}
    </div>
  );
};
