import { FieldErrors, useFormContext } from "react-hook-form";
import styles from "../signup.module.scss";
import inputStyles from "../../../components/input/input.module.scss";
import { Registration } from "../../../types/registration.types.ts";
import { ErrorSummary } from "../summary/ErrorSummary.tsx";
import { useEffect, useState } from "react";
import {
  About,
  Contact,
  Food,
  PlusOneSummary,
  SjufPractical,
} from "../summary/SummarySections.tsx";
import Container from "../../../components/layout/Container.tsx";
import { useSignup } from "../SignupContext.tsx";

const extractErrorMessages = (error: any): string[] => {
  if (Array.isArray(error)) {
    return error.flatMap(extractErrorMessages);
  } else if (typeof error === "object" && error !== null) {
    return error.message
      ? [error.message]
      : Object.values(error).flatMap(extractErrorMessages);
  } else {
    return [];
  }
};

const listOfallErrorMessages = (
  errors: FieldErrors<Registration>,
): string[] => {
  return Object.values(errors).flatMap(extractErrorMessages);
};

export const Summary = () => {
  const { watch, formState, clearErrors } = useFormContext<Registration>();
  const formValues = watch();
  const { setWantsReceipt, wantsReceipt, mail, setMail } = useSignup();

  const [allErrors, setAllErrors] = useState<string[]>([]);

  useEffect(() => {
    setAllErrors(listOfallErrorMessages(formState.errors));
  }, [formState.isSubmitting]);

  useEffect(() => {
    return () => {
      setAllErrors([]);
      return clearErrors();
    };
  }, []);
  return (
    <Container>
      <>
        <div className={styles.summary}>
          <About values={formValues} />
          <Contact values={formValues} />
          <SjufPractical values={formValues} />
          <Food values={formValues} />
          <PlusOneSummary values={formValues} />
        </div>
        {formState.isSubmitted && allErrors.length > 0 ? (
          <ErrorSummary errors={allErrors} />
        ) : null}
        <div style={{ display: "flex", flexDirection: "column", gap: "1rem" }}>
          <div
            style={{
              display: "flex",
              gap: "1rem",
              border: "6px solid gold",
              padding: "1rem",
            }}
          >
            <label htmlFor={"kvittering"} className={inputStyles.label}>
              Do you want a receipt of your registration?
            </label>
            <input
              style={{ marginBottom: "0.5rem" }}
              id={"kvittering"}
              type={"checkbox"}
              value={undefined}
              checked={wantsReceipt || false}
              onChange={(e) => {
                setWantsReceipt(e.target.checked);
              }}
            />
          </div>

          {wantsReceipt && (
            <div style={{ display: "flex", flexDirection: "column" }}>
              <label htmlFor={"mail"} className={inputStyles.label}>
                Your email
              </label>
              <input
                id={"mail"}
                value={mail}
                onInput={(event) => setMail(event.currentTarget.value)}
                className={inputStyles.inputText}
              />
            </div>
          )}
        </div>
      </>
    </Container>
  );
};
