import axios from "axios";
import { Registration } from "../../types/registration.types.ts";

const axiosClient = axios.create({ baseURL: "/api" });
const registerEndpoint = "/register";
const htmlEndpoint = "/html";

interface SignupApi {
  createRegistration: (
    registration: Registration,
    mail?: string,
  ) => Promise<void>;
  getDataHtml: (registration: Registration) => Promise<string>;
}

const signupApi: SignupApi = {
  createRegistration: async (
    registration: Registration,
    mail?: string,
  ): Promise<void> => {
    await axiosClient.post<void>(`${registerEndpoint}`, {
      registration: registration,
      mail: mail,
    });
  },
  getDataHtml: async (registration: Registration): Promise<string> => {
    const { data } = await axiosClient.post<string>(
      `${htmlEndpoint}`,
      registration,
    );
    return data;
  },
};

export default signupApi;
