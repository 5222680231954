import jacob from "../../assets/jacob.jpg";
import helene from "../../assets/helene.jpg";
import michael from "../../assets/michael.jpg";
import leah from "../../assets/leah.jpg";
import philip from "../../assets/philip.jpg";
export type BoardMember = {
  img: string;
  name: string;
  about: string;
};

export const boardMembers1: Array<BoardMember> = [
  {
    img: jacob,
    name: "Jakob Golombek",
    about: "Chairman",
  },
  {
    img: leah,
    name: "Leah Levin Bodd",
    about: "Vice chairman",
  },
  {
    img: michael,
    name: "Michael Golombek",
    about: "Financial manager",
  },
];
export const boardMembers2: Array<BoardMember> = [
  {
    img: helene,
    name: "Helene Malting",
    about: "PR manager",
  },

  {
    img: philip,
    name: "Philip Melchior",
    about: "Event manager",
  },
];
