import { Fieldset } from "../../../components/Fieldset.tsx";
import { useEffect, useRef } from "react";
import styles from "./summary.module.scss";

export const ErrorSummary = ({ errors }: { errors: string[] }) => {
  const ref = useRef<HTMLDivElement>(null);
  useEffect(() => {
    console.log(ref.current);
    ref.current?.focus();
    ref.current?.scrollIntoView({
      behavior: "smooth",
      block: "center",
    });
  }, []);
  return (
    <div ref={ref} className={styles.errorSummary} tabIndex={-1}>
      <Fieldset title={"You need to fill out the following fields"}>
        {errors.map((error) => (
          <p key={error}>{error}</p>
        ))}
      </Fieldset>
    </div>
  );
};
