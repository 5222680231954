import cn from "classnames";

type IconProps = {
  type: string;
  modifierClass?: string;
};

export const Icon = ({ type, modifierClass }: IconProps) => {
  return (
    <span className={cn("material-symbols-outlined", modifierClass)}>
      {type}
    </span>
  );
};
