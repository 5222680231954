import { BoardMember } from "./board.ts";
import styles from "./boardmembers.module.scss";
type BoardMemberCardProps = {
  boardMember: BoardMember;
};

export const BoardMemberCard = ({ boardMember }: BoardMemberCardProps) => {
  return (
    <div className={styles.boardMemberCard}>
      <img src={boardMember.img} alt={"Member image"} />
      <span>{boardMember.name}</span>
      <p>{boardMember.about}</p>
    </div>
  );
};
