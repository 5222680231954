import {
  FieldPath,
  FieldValues,
  useController,
  useFormContext,
} from "react-hook-form";
import { InputHTMLAttributes } from "react";
import styles from "./input.module.scss";
type InputTextProps<T extends FieldValues> = {
  name: FieldPath<T>;
  label: string;
  helpText?: string;
} & InputHTMLAttributes<HTMLInputElement>;

export const InputText = <T extends FieldValues>({
  name,
  label,
  helpText,
  ...rest
}: InputTextProps<T>) => {
  const { control } = useFormContext();
  const { field } = useController({ name, control });
  return (
    <div className={styles.inputTextContainer}>
      <label htmlFor={name} className={styles.label}>
        {label}
      </label>
      {helpText && (
        <span id={`${name}_helpText`} className={styles.helpText}>
          {helpText}
        </span>
      )}
      <input
        {...rest}
        {...field}
        name={name}
        id={name}
        className={styles.inputText}
      />
    </div>
  );
};
