import { HTMLProps, ReactNode } from "react";
import cn from "classnames";
import styles from "./layout.module.scss";

type ContainerProps = {
  children: ReactNode;
} & HTMLProps<HTMLDivElement>;

const Container = ({ children, className, ...rest }: ContainerProps) => {
  return (
    <div className={cn(className, styles.container)} {...rest}>
      {children}
    </div>
  );
};
export default Container;
