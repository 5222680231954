import Dl from "../../../components/Dl.tsx";
import styles from "./summary.module.scss";
import {
  ContactInformation,
  Name,
  Registration,
} from "../../../types/registration.types.ts";
import { inputOrNoAnswer } from "../signup.utils.ts";
import { ReactElement } from "react";

type SummaryEntriesProps = {
  values: Registration;
};

export const SjufPractical = ({ values }: SummaryEntriesProps) => {
  const participatingDaysList = values.participatingDays
    .filter((value) => value.participating)
    .map((value) => value.date);
  return (
    <Panel>
      <Dl>
        <Dl.Entry title={"Participating days"} vertical>
          {participatingDaysList.length > 0 ? (
            <div>
              {participatingDaysList.map((value) => (
                <p>{value}</p>
              ))}
            </div>
          ) : (
            inputOrNoAnswer(undefined)
          )}
        </Dl.Entry>
        <Dl.Entry title={"Stay at SJUF hotel"}>
          {inputOrNoAnswer(values.wantsSjufHotel)}
        </Dl.Entry>
        <Dl.Entry title={"Additional information"} vertical>
          {inputOrNoAnswer(values.other)}
        </Dl.Entry>
      </Dl>
    </Panel>
  );
};
const valueOrThrow = (value: any | undefined): any => {
  if (value === undefined) {
    throw Error("Undefined");
  }
  return value;
};
const concatName = (name?: Name) => {
  return name !== undefined
    ? valueOrThrow(name.firstname) + name.middlename || "" + name.lastname
    : undefined;
};
export const About = ({ values }: SummaryEntriesProps) => {
  const name = concatName(values.person.name);
  return (
    <Panel>
      <Dl>
        <Dl.Entry title={"Name"}>{inputOrNoAnswer(name)}</Dl.Entry>
        <Dl.Entry title={"Birthdate"}>
          {inputOrNoAnswer(values.person.birthdate)}
        </Dl.Entry>
        <Dl.Entry title={"Nationalty"}>
          {inputOrNoAnswer(values.person.nationality)}
        </Dl.Entry>
        <Dl.Entry title={"Has a relation to a jewish community, etc"} vertical>
          {inputOrNoAnswer(values.connection.hasRelationToJewishCommunity)}
        </Dl.Entry>
        {values.connection.hasRelationToJewishCommunity === "YES" ? (
          <Dl.Entry title={"Relation to a jewish community"} vertical>
            {inputOrNoAnswer(values.connection.relationToJewishCommunity)}
          </Dl.Entry>
        ) : (
          <></>
        )}
        {values.connection.hasRelationToJewishCommunity === "NO" ? (
          <>
            <Dl.Entry title={"Reference person name"} vertical>
              {inputOrNoAnswer(
                concatName(values.connection.referencePerson?.name),
              )}
            </Dl.Entry>
            <Dl.Entry title={"Reference person countrycode"} vertical>
              {inputOrNoAnswer(
                values.connection.referencePerson?.contactInformation
                  ?.countrycode,
              )}
            </Dl.Entry>
            <Dl.Entry title={"Reference person phonenumber"} vertical>
              {inputOrNoAnswer(
                values.connection.referencePerson?.contactInformation
                  ?.phonenumber,
              )}
            </Dl.Entry>
          </>
        ) : (
          <></>
        )}
      </Dl>
    </Panel>
  );
};

export const Contact = ({ values }: SummaryEntriesProps) => {
  const concatPhone = (contact?: ContactInformation) => {
    const countrycode =
      contact?.countrycode !== "" ? `+${contact?.countrycode}` : undefined;
    const phone =
      contact?.phonenumber !== "" ? contact?.phonenumber : undefined;
    if (countrycode === undefined || phone === undefined) return undefined;
    return `${countrycode} ${phone}`;
  };

  const phone = concatPhone(values.person.contactInformation);
  const email = values.person.contactInformation?.email;
  return (
    <Panel>
      <Dl>
        <Dl.Entry title={"Email"}>{inputOrNoAnswer(email)}</Dl.Entry>
        <Dl.Entry title={"Phone"}>{inputOrNoAnswer(phone)}</Dl.Entry>
      </Dl>
    </Panel>
  );
};
export const Food = ({ values }: SummaryEntriesProps) => {
  return (
    <Panel>
      <Dl>
        <Dl.Entry title={"Allergies"}>
          {inputOrNoAnswer(values.food.allergies?.type)}
        </Dl.Entry>
        {values.food.allergies?.type == "YES" ? (
          <Dl.Entry title={"Allergies description"} vertical>
            {inputOrNoAnswer(values.food.allergies?.allergies)}
          </Dl.Entry>
        ) : (
          <></>
        )}
        <Dl.Entry title={"Keeping kosher?"}>
          {inputOrNoAnswer(values.food.kosherWine)}
        </Dl.Entry>
      </Dl>
    </Panel>
  );
};
export const PlusOneSummary = ({ values }: SummaryEntriesProps) => {
  return (
    <Panel>
      <Dl>
        <Dl.Entry title={"Do you have a plus one"}>
          {inputOrNoAnswer(values.partner.hasPlusOne)}
        </Dl.Entry>
        {values.partner.hasPlusOne === "YES" ? (
          <>
            <Dl.Entry title={"Plus one's name"} vertical>
              {inputOrNoAnswer(concatName(values.partner.name))}
            </Dl.Entry>
            <Dl.Entry title={"Is plus one jewish"} vertical>
              {inputOrNoAnswer(values.partner.isJewish)}
            </Dl.Entry>
          </>
        ) : (
          <></>
        )}
      </Dl>
    </Panel>
  );
};
const Panel = ({ children }: { children: ReactElement }) => {
  return <div className={styles.panel}>{children}</div>;
};
